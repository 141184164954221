<template>
  <div>
    <div class="w-full">
      <el-carousel :interval="3000" type="card" class="review-carousel h-235px sm:h-360px" indicator-position="none">
        <el-carousel-item v-for="(item, i) in commentsList" :key="i" class="review-item-box">
          <div class="review-item w-full max-w-430px bg-#fff p-18px sm:p-36px rounded-16px mx-auto">
            <div class="flex mb-12px sm:mb-24px">
              <el-image :src="item.avatar" alt="" class="w-34px h-34px sm:h-48px sm:w-48px mr-6px sm:mr-12px shrink-0" />
              <div>
                <div class="fs-14 sm:fs-18 c-#282A37 font-600 mb-4px sm:mb-8px">{{ item.name }}</div>
                <div class="fs-12 sm:fs-16 c-#828499 font-500 flex items-center gap-4px">
                  <SvgIcon name="position" class="w-12px h-12px sm:w-16px sm:h-16px c-#828499"></SvgIcon> {{ item.address }}
                </div>
              </div>
            </div>
            <div class="c-#616478 text-12px leading-14px h-98px sm:text-16px sm:leading-24px mb-12px sm:mb-24px line-clamp-7 sm:h-144px">{{ item.content }}</div>
            <div class="flex gap-4px md:gap-10px">
              <nuxt-icon v-for="item in 5" :key="item" name="star-fill" color="#FFB600" class="w-12px h-12px fs-12 md:fs-16"></nuxt-icon>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script lang="ts" setup>

	const commentsList = [
		{
			avatar: '/imgs/home/avatar/avatar1.png',
			name: 'PixelPioneer',
			content: `I started using it quite early on, and over the past few months, it has been a great help to me. I have also witnessed Arvin's growth, with its features becoming increasingly powerful and its interface ever more user-friendly and intuitive. I highly recommend this product.`,
			address: 'India'
		},
		{
			avatar: '/imgs/home/avatar/avatar2.png',
			name: 'SpiceScribe',
			content: `When you're comparing with Google Translate and ChatGPT na, this one is far more handy. Start using it once and, I'm telling you, you'll be like "How I'm even managing without this before?" It's become such a habit, isn't it? Haha.`,
			address: 'US'
		},
		{
			avatar: '/imgs/home/avatar/avatar3.png',
			name: 'ネコタロウ',
			content: `わあ、Arvinは私の個人アシスタントのようです。仕事に使うととても便利で、私が自分でやるよりも上手です！`,
			address: 'Japan'
		},
		{
			avatar: '/imgs/home/avatar/avatar4.png',
			name: 'LoboSolitario',
			content: `Arvin tiene la capacidad de resumir y traducir, lo cual es de gran ayuda para mí. Puedo utilizar esta función para leer artículos en otros idiomas y también para ayudarme a redactar respuestas.`,
			address: 'Spain'
		},
		{
			avatar: '/imgs/home/avatar/avatar6.png',
			name: 'EffizienzMeister',
			content: `Das übertrifft meine Erwartungen, wirklich nützlich und effizienzsteigernd. Ich habe es sogar meinen Freunden empfohlen.`,
			address: 'Germany'
		},
		{
			avatar: '/imgs/home/avatar/avatar7.png',
			name: 'Wanderlust',
			content: `After using it for a week, I really love this extension. pulling up with the hotkey is easier than using Google or Ghat GPT. It not only helps me with quick questions and answers, translations, but also improves my text writing efficiency!！`,
			address: 'Australia'
		},
		{
			avatar: '/imgs/home/avatar/avatar8.png',
			name: '摔倒的红烧肉',
			content: `很棒，功能很强大，界面设计清晰，用起来也很便捷，向你们发送了我的反馈，希望继续优化。`,
			address: 'China'
		},
		{
			avatar: '/imgs/home/avatar/avatar9.png',
			name: 'MysticSoul',
			content: `I use Arvin almost every day, and it greatly improves my work efficiency. The free daily usage helps me solve many problems, and the package is also cost-effective. I highly recommend everyone to give it a try. A week ago, I provided feedback to the email and it was quickly resolved.`,
			address: 'UK'
		},
		{
			avatar: '/imgs/home/avatar/avatar10.png',
			name: 'Магический Феникс',
			content: `Аrvin- мой надежный помощник, который всегда готов помочь мне с работой, открывая боковую панель. Я уже порекомендовал его своим друзьям и получил больше кредитов благодаря рекомендациям. `,
			address: 'Russia'
		},
		{
			avatar: '/imgs/home/avatar/avatar11.png',
			name: 'WhisperWillow',
			content: `After using it for a while，I want to say  "thank you so much. impossible without your support!" `,
			address: 'Norway'
		}
	];
</script>
<style lang="less" scoped>
.review-carousel {
  :deep(.el-carousel__container) {
    height: 100% !important;
  }
}
.review-item-box {
  background-color: #F8F8FC;
  :deep(.el-carousel__mask) {
    background-color: #F8F8FC;
  }
  .review-item {
    transition: all 0.2s ease;
    opacity: 0;
    &:hover {
      opacity: 0.8;
    }
  }
  &.is-in-stage {
    .review-item {
      opacity: 0.7;
    }
  }
  &.is-active {
    background-color: transparent;
    // transition: all 0.2s ease;
    .review-item {
      opacity: 1;
      box-shadow: 8px 8px 32px 0px rgba(0, 0, 56, 0.08);
    }
  }
}
</style>
