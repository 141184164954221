<template>
  <div>
    <template v-if="!user.userId || !token">
      <div class="decoration-none hidden lg:block" @click="handleLogin">
        <a-button type="default" size="small" class="bg-#f3f3ff! c-primary! font-600">Sign in</a-button>
      </div>

      <div class="decoration-none lg:hidden text-12px c-primary font-500" @click="handleLogin">Sign
        in</div>
    </template>
    <template v-else>
      <div id="avatarDashHeader" class="fade-in cp flex-center" @click="handleToDashboard">
        <!-- <el-avatar v-if="user.avatarUrl" :src="user?.avatarUrl" :size="32" alt="arvin" /> -->
        <!-- <nuxt-icon v-else name="default-avatar" class="fs-32" :filled="true"></nuxt-icon> -->
        <div class="relative inline-block">
          <div class="relative flex-center">
            <ClientOnly>
              <div :class="['cp flex-center', assetInfo?.plan !== 'free' ? 'absolute h-28px' : '']">
                <el-avatar v-if="user?.avatarUrl" :src="user?.avatarUrl" :size="assetInfo?.plan === 'free' ? 32 : 28" alt="" />
                <svg-icon v-else name="default-avatar" :size="assetInfo?.plan === 'free' ? 32 : 28" :filled="true"></svg-icon>
              </div>
              <div v-if="assetInfo?.plan !== 'free'" class="plan-border"></div>
            </ClientOnly>
          </div>
          <ClientOnly>
            <div v-if="assetInfo?.plan !== 'free'" class="flex absolute right--1px bottom--3px c-primary fs-14 plan-flag">
              <svg-icon class="w-14px h-14px" size="10" :name="`plan-${assetInfo?.plan}`"></svg-icon>
            </div>
          </ClientOnly>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
	import { storeToRefs } from 'pinia';
	import { useFeatCostStore } from '~/store/featCost';

	const loginPath = ref('');
	const token = ref('');
	const user = ref({
		avatarUrl: '',
		userId: '',
		role: ''
	});
	const route = useRoute();
	const featCostStore = useFeatCostStore();
	const { userAssets } = storeToRefs(featCostStore);
	// @ts-ignore
	const assetInfo = userAssets as Ref<UserAsset>;
	const { handleToDashboard } = useNavigation();
	onMounted(() => {
		user.value = getUserInfo();
		token.value = getArvinSessionToken();
		loginPath.value = `/user/login?next=${route.path}${window.location.search}`;
	});

	const handleLogin = () => {
		navigateTo(loginPath.value);
	};
</script>

<style lang="less" scoped>
.plan-flag {
	background: linear-gradient(112deg, #FFFFFF 17%, #CBCDFF 85%);
	border-radius: 10px;
}

.plan-border {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: linear-gradient(132deg, #B5B7FE 16%, #676BF6 83%);
}
</style>
