export const useLocation = () => {
  const loadingPromise = useState<Promise<{
    country: string;
    in_eu: boolean;
  } | void> | null>('locationLoadingPromise', () => null);
  const getLocation = async () => {
    let location;
    try {
      location = await fetch('https://ipapi.co/json/')
        .then(response => response.json()) as { country: string, in_eu: boolean };
    } finally {
      sessionStorage.setItem('__country', location?.country || 'NO');
    }
    return location;
  };

  const getCountry = async () => {
    if (loadingPromise.value) {
      await loadingPromise.value;
      return sessionStorage.getItem('__country');
    }
    if (sessionStorage.getItem('__country')) {
      return sessionStorage.getItem('__country');
    }
    loadingPromise.value = getLocation().finally(() => {
      loadingPromise.value = null;
    });
    await loadingPromise.value;
    return sessionStorage.getItem('__country');
  };

  return {
    getCountry
  };
};
