<template>
  <div class="h-full">
    <NuxtLayout>
      <NuxtPage></NuxtPage>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
	import { useInitApp } from './composables/useInitApp';
	useInitApp();
	const route = useRoute();
	if (import.meta.client) {
		watch(() => route.fullPath, () => {
			reportEvent('page_view', { entry_type: window.location.href });
		}, { immediate: true });
	}
</script>

<style lang="less">

</style>
