interface UtmParams {
  media_source?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_id?: string;
}

const defaultUtmParams: UtmParams = {
  media_source: 'Others',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_id: ''
};

const UTM_LOCAL_KEY = 'utmParams';
const UTM_COOKIE_KEY = 'utm_params';

function setCookie (name: string, value: string, days = 30) {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

function getCookie (name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift() || null;
  }
  return null;
}

// https://arvin.chat/webstore?utm_source=google&utm_medium=banner&utm_campaign=Arvin[…]6-search-ins&utm_id=2094970262&utm_term=click&utm_content=arvin
function getUtmParamsFromUrl (): UtmParams {
  const searchParams = new URLSearchParams(window.location.search);
  let referer = document.referrer; let mediaSource = '';
  if (referer.includes('youtube.com')) {
    mediaSource = 'YouTube';
  } else if (referer.includes('twitter.com')) {
    mediaSource = 'Twitter';
  } else if (referer.includes('facebook.com')) {
    mediaSource = 'Facebook';
  }
  return {
    media_source: mediaSource || undefined,
    utm_source: searchParams.get('utm_source') || undefined,
    utm_medium: searchParams.get('utm_medium') || undefined,
    utm_campaign: searchParams.get('utm_campaign') || undefined,
    utm_id: searchParams.get('utm_id') || undefined
  };
}

function storeUtmParams (params: UtmParams): void {
  if (!localStorage.getItem(UTM_LOCAL_KEY) && !getCookie(UTM_COOKIE_KEY)) {
    const paramsStr = JSON.stringify(params);
    localStorage.setItem(UTM_LOCAL_KEY, paramsStr);
    setCookie(UTM_COOKIE_KEY, paramsStr);
  }
}

function getStoredUtmParams (defaultUtmParams?: UtmParams): UtmParams | null {
  const storedParams = localStorage.getItem(UTM_LOCAL_KEY) || getCookie(UTM_COOKIE_KEY);
  return storedParams ? JSON.parse(storedParams) : (defaultUtmParams || null);
}

const utmParams = ref<UtmParams | null>(null);
export function useUtmTracking () {
  // onMounted need to parent
  const initUTM = () => {
    const storedParams = getStoredUtmParams();
    if (storedParams) {
      utmParams.value = storedParams;
      return;
    }

    const urlUtmParams = getUtmParamsFromUrl();
    if (urlUtmParams.utm_source || urlUtmParams.utm_medium || urlUtmParams.media_source || urlUtmParams.utm_campaign || urlUtmParams.utm_id) {
      utmParams.value = urlUtmParams;
      storeUtmParams(urlUtmParams);
      reportEvent('Web_Entry_From_Promotion_Link');
    }
  };

  const reportAndClearStoredParams = () => {
    const params = getStoredUtmParams();
    if (params) {
      localStorage.removeItem(UTM_LOCAL_KEY);
      setCookie(UTM_COOKIE_KEY, '', -1);
      utmParams.value = null;
    }
  };

  return {
    initUTM,
    utmParams,
    defaultUtmParams,
    getStoredUtmParams,
    reportAndClearStoredParams
  };
}
