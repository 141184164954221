<template>
  <client-only>
    <el-dialog
      v-model="dialogVisible" class="login-arvin-dialog w-[calc(100%-36px)]! md:w-520px! m-18px! md:m-auto! p-24px top-[calc(40vh-192px)] md:top-15vh"
      :close-on-click-modal="false"
      :z-index="1000"
      :before-close="handleClose">
      <div class="p-24px">
        <login-registry is-dialog/>
      </div>
    </el-dialog>
  </client-only>
</template>
<script setup lang="ts">

	const dialogVisible = ref(false);

	// const route = useRoute();
	function open () {
		dialogVisible.value = true;
	}

	const handleClose = (done: () => void) => {
		window.localStorage.removeItem('next');
		done();
	};

	function close () {
		dialogVisible.value = false;
	}

	// function handleLogin () {
	// 	const loginPath = `/user/login?next=${route.path}${window.location.search}`;
	// 	navigateTo(loginPath);
	// }

	defineExpose({
		open,
		close
	});

</script>

<style lang="less">
.login-arvin-dialog {

  &.el-dialog {
    .el-dialog__header {
      height: 0;
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
    }
  }

  .dialog-btn {
    height: 46px;
    width: 196px;
    font-size: 16px;
    justify-content: center;
  }

  .dialog-btn-full {
    background: #8D52F7;
    width: 100%;
    height: 46px;
    font-size: 16px;
    justify-content: center;
  }
  .footer {
    .primary-btn {
      &:hover {
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06);
      }
    }
    .microsoft-btn {
      color: #000;
      border: 1px solid #DDDFE1;
      background: #fff;
    }
  }
}
</style>
