<!--
 * @Author: Sandy
 * @Date: 2023-11-01 10:08:32
 * @LastEditTime: 2024-10-28 17:43:12
 * @Description:
-->
<template>
  <div class="min-h-100vh">
    <!-- <div class="top relative z-99"> -->
    <TheHeader :key="route.fullPath"/>
    <!-- <Topbar v-if="!hideTopBar" :dark="isShadowPage"></Topbar> -->
    <!-- </div> -->
    <slot />
    <TheFooter/>
    <ClientOnly>
      <!-- <LazyChatDrawer ref="chatDrawer"/> -->
      <LoginDialog ref="loginDialog"/>
      <CookiesManage></CookiesManage>
      <!-- <ExclusiveOfferDialog :ref="setExclusiveDialogRef"/> -->
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
	// const chatDrawer = ref(null);
	const loginDialog = ref(null);
	const route = useRoute();
	// provide('chatDrawer', chatDrawer);
	provide('loginDialog', loginDialog);
	const isShadowPage = ref(false);
	const copyTransparent = ref(false);
	const hideCopyright = ref(false);
	const hideTopBar = ref(false);
	onMounted(() => {
		const token = getArvinSessionToken();
		const path = route.path;
		checkPath();
	});

	watch(() => route.path, () => {
		checkPath();
	});

	const checkPath = () => {
		isShadowPage.value = ['/invite', '/feedback'].includes(route.path);
		hideTopBar.value = route.path.startsWith('/share/chat/');
		copyTransparent.value = ['/invite'].includes(route.path);
		hideCopyright.value = ['/user/dashboard', '/user/login', '/user/subscription'].includes(route.path);
	};

</script>
