export function useIsMobile () {
  const isMobileState = ref(false);

  if (import.meta.server) {
    const { ssrContext } = useNuxtApp();
    const userAgent = ssrContext?.event.node.req.headers['user-agent'] || '';
    isMobileState.value = /mobile|android|iphone|ipad|phone/i.test(userAgent);
  } else {
    const userAgent = navigator.userAgent || '';
    isMobileState.value = /mobile|android|iphone|ipad|phone/i.test(userAgent);
  }

  return isMobileState;
}
