import type { Config } from './types';
export default {
  env: '',
  storageKey: 'amb_storage_',
  homepageUrl: 'https://arvin.chat',
  baseUrl: 'https://api.tryarvin.com/bp/',
  loginUrl: 'https://arvin.chat/user/login',
  payUrl: 'https://arvin.chat/user/subscription',
  termsOfService: 'https://arvin.chat/terms-of-service',
  privacyPolicy: 'https://arvin.chat/privacy-policy',
  creditRuleUrl: 'https://arvin.chat/help/credit-deduction-rule',
  dashboardUrl: 'https://arvin.chat/user/dashboard',
  settingsUrl: 'https://arvin.chat/user/settings',
  casApiUrl: 'https://caspian-data.tryarvin.com/caspian/',
  billingUrl: 'https://arvin.chat/user/billing',
  i: '6556dd30834448b1',
  k: '5f216ded8d46414189cef1df2e376bd1',
  a: 'APPC6FO724ZWYXD4',
  summaryServerUrl: 'https://api.tryarvin.com/as',
  feedbackUrl: 'https://arvin.chat/feedback/',
  arvinServerUrl: 'https://arvin.chat',
  language: 'en',
  version: 1,
  msalClientId: '3ee3b409-ce8f-4553-9654-74c13106d128',
  msalAuthority: 'https://login.microsoftonline.com/common',
  msalRedirectUrl: 'https://arvin.dev.eks-089.atcloudbox.com/user/microsoftlogin',
  bpMSLoginUrl: 'https://arvin.chat/api/users/microsoft',
  googleMeasurementId: 'G-YY5TFG7205',
  consoleUrl: 'https://console.arvin.chat/console/arvinlogin',
  contactUrl: 'https://arvin-web.zendesk.com/hc/en-us/requests/new',
  arvinLogoUrl: 'https://logo.arvin.chat'
} as Config;
