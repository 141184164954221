/*
 * @Author: Sandy
 * @Date: 2024-11-29 12:22:35
 * @LastEditTime: 2024-11-29 12:27:36
 * @Description: Middleware to handle device_id cookie management.
 */

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) {
    // Retrieve the request event to access headers
    const event = useRequestEvent();
    const cookies = event.node.req.headers.cookie || '';
    const hasDeviceId = cookies.includes('device_id=');
    console.log('nuxtMiddleware', `[${cookies}]`);
    console.log('nuxtMiddleware hasDeviceId', `[${hasDeviceId}]`);

    // Function to generate a random UUID
    const randomUUID = (randomLength = 8) => {
      return Number(Math.random().toString().substring(2, randomLength) + Date.now()).toString(36);
    };
    console.log('nuxtMiddleware env', process.env);
    console.log('nuxtMiddleware domain', process.env.NUXT_DOMAIN_URL);
    // If device_id cookie is not present, set a new one
    if (!hasDeviceId) {
      const deviceId = `arvin-${randomUUID()}-${Date.now()}`;

      // Determine cookie attributes based on environment
      const secure = process.env.NUXT_PUBLIC_ENV !== 'development';
      const sameSite = process.env.NUXT_PUBLIC_ENV !== 'development' ? 'none' : 'lax';
      const maxAge = 60 * 60 * 24 * 365 * 0.5; // Half a year
      const domain = process.env.NUXT_PUBLIC_ENV !== 'development' ? process.env.NUXT_DOMAIN_URL || '' : '';

      // Set the device_id cookie with appropriate attributes
      event.node.res.setHeader('Set-Cookie', `device_id=${deviceId}; Domain=${domain}; Path=/; Secure; SameSite=${sameSite}; Max-Age=${maxAge}`);
    }
  }
});
