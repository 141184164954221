<template>
  <section class="w-full footer mt-80px md:mt-160px mb-80px lg:mb-160px py-40px lg:py-110px px-16px">
    <div class="mask"></div>
    <div class="flex-column-center relative z-2">
      <h2 class="c-#282A37 text-center py-0! mb-0 fs-28 md:fs-60 mb-12px! lg:mb-16px! font-600!">{{ title }}</h2>
      <p class="c-#282A37 text-14px sm:text-18px mb-18px sm:mb-36px text-center" v-html="desc"></p>
      <div class="flex gap-24px flex-wrap justify-center">
        <NuxtLink :to="arvinLogoUrl" external class="black-btn lt-md:fs-14 h-48px w-300px md:h-68px md:fs-20" @click="reportHomeEvent('logo', 'bottom_toolbar_btn')">Arvin Logo</NuxtLink>
        <NuxtLink to="/en/home" external class="white-btn lt-md:fs-14 h-48px w-300px md:h-68px md:fs-20" @click="reportHomeEvent('webapp', 'bottom_toolbar_btn')">Webapp - Try for Free</NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
	const config = useNuxtApp().$arvinConfig as any;
	defineProps({
		title: {
			type: String,
			default: 'Get Started for Free'
		},
		desc: {
			type: String,
			default: 'Write 10x faster, engage your audience, and never struggle with the blank page again.'
		}
	});
	const reportHomeEvent = (functionType: string, entryType: string) => {
		reportEvent('Web_Homepage_Product_Click', {
			function_type: functionType,
			entry_type: entryType
		});
	};
	const arvinLogoUrl = ref('');
	onMounted(() => {
		arvinLogoUrl.value = config.arvinLogoUrl;
	});
</script>

<style lang="less" scoped>
.footer {
	position: relative;
  background-size: 100% 100%;
  border-radius: 24px;
	overflow: hidden;
	background: linear-gradient(90deg, #C7C5FF 38%, #D9EEFF 78%);
	.mask {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(248, 248, 252, 0.8) 5%, rgba(217, 238, 255, 0.1) 100%);
	}
}
.white-btn {
	background: rgba(255, 255, 255, 0.6);
	border: 1px solid #FFFFFF;
	backdrop-filter: blur(10px);
}
</style>
