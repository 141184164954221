<template>
  <div class="gap-16px lg:gap-36px">
    <a
      v-if="browser === 'Chrome'" :class="`white-btn w-233px h-48px ${customClass}`"
      target="_blank"
      :href="googleDownloadUrl" aria-label="download arvin"
      @click="handleDownload">
      <nuxt-icon name="chrome" class="fs-16 pr-12px" :filled="true" />
      <span>{{ btnText ? btnText : 'Add to Chrome' }}</span>
    </a>
    <a
      v-else-if="browser === 'Edge'" :class="`white-btn w-233px h-48px ${customClass}`"
      target="_blank"
      :href="edgeDownloadUrl" aria-label="download arvin"
      @click="handleDownload">
      <nuxt-icon name="edge" class="fs-16 pr-12px" :filled="true" />
      <span>{{ btnText ? btnText : 'Add to Edge' }}</span>
    </a>
    <el-popover
      v-else placement="bottom-start"
      :show-arrow="false"
      trigger="click"
      class="white-btn first-btn"
      popper-class="p-0px! rounded-16px! w-auto!"
      :teleported="false"
      @before-enter="showBrowser=true"
      @after-leave="showBrowser=false" >
      <template #reference>
        <div
          :class="`white-btn w-233px h-48px ${customClass}`">
          <span class="relative w-16px mr-4px">
            <nuxt-icon name="chrome" class="fs-14 absolute translate-y--1/2" :filled="true" />
          </span>
          <span class="mr-24px">{{ 'Add to Chrome' }}</span>
          <svg-icon name="arrow" class="w-10px md:w-18px" :class="showBrowser ? 'rotate-180' : 'rotate-0'"></svg-icon>
        </div>
      </template>
      <div class="dropdown-btns hover:opacity-100! p-12px flex h-auto! flex-col">
        <a
          class="item-btn"
          target="_blank"
          :href="googleDownloadUrl" aria-label="download arvin"
          @click="handleDownload">
          <nuxt-icon name="chrome" class="fs-14 lg:fs-28 pr-12px lg:pr-12px" :filled="true" />
          <span>{{ btnText ? btnText : 'Add to Chrome' }}</span>
        </a>
        <a
          class="item-btn"
          target="_blank"
          :href="edgeDownloadUrl" aria-label="download arvin"
          @click="handleDownload">
          <nuxt-icon name="edge" class="fs-14 lg:fs-28 pr-12px lg:pr-12px" :filled="true" />
          <span>{{ btnText ? btnText : 'Add to Edge' }}</span>
        </a>
      </div>
    </el-popover>
  </div>
</template>
<script lang="ts" setup>

	defineProps({
		customClass: {
			type: String,
			default: ''
		},
		btnText: {
			type: String,
			default: ''
		},
		btnBg: {
			type: String,
			default: '#282A37'
		},
		btnTextSuffix: {
			type: String,
			default: `It's Free！`
		}
	});

	const showBrowser = ref(false);
	const runtimeConfig = useRuntimeConfig();
	const { googleDownloadUrl, edgeDownloadUrl } = runtimeConfig.public;
	const browser = getBrowserName();
	const emit = defineEmits(['download']);
	function handleDownload () {
		// reportEvent('Web_Homepage_Install_Btn_Click');
		emit('download');
	}
</script>

<style lang="less" scoped>

.dropdown-btns {
  @apply hover:opacity-80 lg:mx-0 w-233px lt-md:w-290px h-40px rounded-40px text-14px lg:h-60px lg:rounded-60px lg:text-20px font-500;
}
.item-btn {
  display: flex;
  align-items: center;
  color: #282A37;
  cursor: pointer;
  text-decoration: none;
  @apply hover:bg-#F7F8FA lg:mx-0 w-full h-40px lg:h-52px p-12px rounded-12px text-14px lg:h-60px lg:text-18px font-500;
}
</style>
