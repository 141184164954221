<template>
  <div class="w-full overflow-hidden px-20px lg:px-60px pt-60px rounded-tl-36px rounded-tr-36px bg-#fff">
    <section class="the-footer c-deep-90">
      <div class="flex-col lg:flex-row lg:flex-between! lg:items-start! flex-wrap mb-36px lg:gap-20px lg-flex-nowrap">
        <div>
          <div class="text-left lg:max-w-400px lg:h-180px">
            <div class="flex items-center mb-16px hover:opacity-80 decoration-none c-deep-90 cp" @click="linkToWebapp()">
              <img src="~/assets/imgs/logo-img.png" class="w-42px lg:w-42px p-2px pb-4px" alt="arvin logo" srcset="">
              <span class="text-24px lg:text-24px font-700 ml-8px">Arvin</span>
            </div>
            <p class="text-14px mb-32px leading-24px w-320px c-444658">Arvin is the AI web application and browser extension powered by GPT-4o, Gemini 1.5, Flux & more. Chat with Arvin from anywhere online for instant research, reading and creativity.</p>
          </div>
          <div class="text-left lg:max-w-400px lg:h-130px">
            <nuxt-link :to="arvinLogoUrl" class="flex items-center mb-16px hover:opacity-80 decoration-none c-deep-90" @click="report('logo maker')">
              <span class="text-24px lg:text-24px font-700 mr-8px">Arvin Logo</span>
              <svg-icon name="arrow-line" class="" size="12"></svg-icon>
            </nuxt-link>
            <p class="text-14px mb-32px leading-24px w-320px c-444658">Arvin Logo Maker is a professional AI-powered logo generator that offers features such as logo and signature generation, etc.</p>
          </div>
        </div>
        <div
          class="footer-links lg:max-w-1305px flex flex-wrap lg:flex-nowrap lg:gap-48px flex-row items-start justify-between mt-48px lg:mt-0">
          <div class="flex-inline flex-col mb-20px lg-mb-0">
            <div class="link-title">Products</div>
            <div class="flex items-start gap-24px ">
              <template v-for="item in menuList[0].subMenus" :key="item.title">
                <div class="flex flex-col flex-grow">
                  <div class="fs-14 font-500 mb-10px">{{ item.title }}</div>
                  <nuxt-link v-for="sub in item.subMenus" :key="sub.link" :to="sub.link" @click="report(sub.title)">{{ sub.title }}</nuxt-link>
                </div>
              </template>
            </div>
          </div>
          <div class="flex-inline flex-col mb-20px lg-mb-0">
            <div class="link-title">Resources</div>
            <div class="flex items-start gap-24px">
              <template v-for="item in menuList[1].subMenus" :key="item.title">
                <div class="flex flex-col">
                  <div class="fs-14 font-500 mb-10px">{{ item.title }}</div>
                  <nuxt-link v-for="sub in item.subMenus" :key="sub.link" :_target="sub.target" :to="sub.link" @click="report(item.title)">{{ sub.title }}</nuxt-link>
                </div>
              </template>

            </div>

          </div>
          <div class="flex-inline flex-col mb-10px lg-mb-0">
            <div class="link-title">Company</div>
            <nuxt-link to="/privacy-policy" @click="report('Privacy Policy')">Privacy
              Policy</nuxt-link>
            <nuxt-link to="/terms-of-service" @click="report('Terms of Service')">Terms of Service</nuxt-link>
            <nuxt-link to="/cookies" @click="report('Cookies Policy')">Cookies Policy</nuxt-link>
            <nuxt-link to="/about" @click="report('About Us')">About Us</nuxt-link>
          </div>
        </div>
      </div>

      <div class="copyright flex-between">
        <div>
          Copyright © Idealabs PTE. LTD.
        </div>
        <div class="flex gap-18px">
          <div class="w-36px h-36px rounded-50% bg-#F7F8FA flex-center cp hover:op-80">
            <a class="c-#616478" href="https://twitter.com/tryarvin" target="_blank" aria-label="visit tryarvin on Twitter">
              <nuxt-icon name="x" class="fs-24 c-#444658" /></a>
          </div>
          <div class="w-36px h-36px rounded-50% bg-#F7F8FA flex-center cp hover:op-80">
            <a
              class="c-#616478" href="https://www.youtube.com/@tryarvin"
              target="_blank"
              aria-label="visit tryarvin on Youtube">
              <nuxt-icon name="youtube2" :filled="true" class="fs-24 c-#444658" />
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
	import { menuList } from '~/composables/useMenus';
	const config = useNuxtApp().$arvinConfig as any;
	const arvinLogoUrl = ref('');

	onMounted(() => {
		arvinLogoUrl.value = config.arvinLogoUrl;
	});

	function report (btnType: string) {
		// reportEvent('Web_Homepage_Footer_Click', { btn_type: btnType })
		reportEvent('Menu_Link_Click', {
			entry_type: 'web-footer',
			function_type: btnType
		});
	}

	function linkToWebapp () {
		report('arvin');
		window.open('/en/home', '_self');
	}

</script>

<style lang="less" scoped>
.the-footer {
  margin: 0 auto;
  @apply w-full max-w-1440px;

  .footer-links {
    .link-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #EBEDF3;
    }

    a {
      color: #444658;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
      transition: color 0.2s;
      margin-bottom: 10px;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  .copyright {
    width: 100%;
    border-top: 1px solid #EBEDF3;
    padding: 24px 0;
    text-align: center;
    color: #444658;
  }
}
</style>
