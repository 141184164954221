<template>
  <div
    class="the-header flex items-center transitions py-16px px-18px lg:px-36px w-100% z-10 md:rounded-74px md:h-74px md:bg-[rgba(255,255,255,0.4)]">
    <div class="flex-between w-full">
      <div><Logo/></div>
      <div class="flex gap-36px">
        <div v-if="notOnlyLogo" class="hidden lg:flex menu-group gap-32px items-center">
          <nuxt-link
            :to="arvinLogoUrl" class="el-dropdown-link decoration-none"
            @click="linkReport('Logo Maker')">
            Logo Maker
          </nuxt-link>
          <el-dropdown
            v-for="(item, i) in menuList" :key="i"
            :placement="'bottom'" :hide-on-click="false"
            :popper-options="{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 34]
                  }
                }
              ]
            }"
            @visible-change="(status) => onMenuVisibleChange(i, status)">
            <span
              class="el-dropdown-link"
              :class="[`${menuHoverStatus[i] ? 'active' : ''}`]">
              <span>{{ item.title }}</span> <nuxt-icon name="arrow" class="fs-12"></nuxt-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <div class="dropdown-menu-box flex p-24px gap-24px items-start">
                  <div
                    v-for="(menu, index) in item.subMenus"
                    :key="index"
                    class="flex flex-col  w-180px fs-16 decoration-none">
                    <div class="font-500 c-#444658 mb-10px pb-10px" border-b="1px solid #EBEDF3">{{ menu.title }}</div>
                    <template
                      v-for="link in menu.subMenus"
                      :key="link.link">
                      <nuxt-link
                        v-if="!link.hidden"
                        class="fs-14 mb-10px leading-none" :to="link.link"
                        @click="linkReport(link.title)">{{ link.title }}</nuxt-link>
                    </template>

                  </div>
                </div>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <nuxt-link
            to="/user/subscription" class="el-dropdown-link decoration-none"
            @click="linkReport('Pricing')">
            Pricing
          </nuxt-link>
          <nuxt-link
            v-if="consoleUrl" :to="consoleUrl"
            class="el-dropdown-link decoration-none">
            Console
          </nuxt-link>
          <nuxt-link
            v-if="consoleUrl" class="el-dropdown-link decoration-none"
            @click="openPromptUpdated">
            optimizePrompts
          </nuxt-link>
        </div>
      </div>

      <div v-if="notOnlyLogo" class="btn-group flex-between gap-16px">
        <!-- <WebAppEntry/> -->
        <template v-if="!user.userId || !token">
          <div class="cp fs-16 c-#282A37 font-600" @click="handleLogin">Sign
            in</div>
        </template>
        <!-- <div
          v-if="pageMounted && !hasArvin" id="tryForHeader"
          class="black-btn fs-14 w-108px h-42px" type="default"
          @click="downloadArvin()">Try for
          Free</div> -->

        <template v-if="user.userId && token">
          <UserAvatar/>
        </template>
        <nuxt-icon name="menu" class="lg:hidden fs-20" @click="handleShowMenu"></nuxt-icon>
      </div>
      <the-header-menu v-if="notOnlyLogo" ref="menuListRef" :menu-list="menuList" class="lg:hidden" />
    </div>
  </div>
</template>

<script setup lang="ts">
	import { storeToRefs } from 'pinia';
	import WebAppEntry from './WebAppEntry.vue';
	import UserAvatar from './UserAvatar.vue';
	// import { useJudgeArvin } from '~/composables/useJudgeArvin';
	import { useFeatCostStore } from '~/store/featCost';
	import { menuList } from '~/composables/useMenus';

	// const { hasArvin, pageMounted } = useJudgeArvin();
	const isMobile = useIsMobile();
	const showBackground = ref(false);
	const headerTop = ref(isMobile.value ? '0' : '24px');
	const route = useRoute();

	const props = defineProps({
		selfHight: {
			type: Boolean,
			default: false
		},
		notOnlyLogo: {
			type: Boolean,
			default: true
		}
	});

	const user = ref({
		avatarUrl: '',
		userId: '',
		role: ''
	});
	const featCostStore = useFeatCostStore();
	const { userAssets } = storeToRefs(featCostStore);
	// @ts-ignore
	// const assetInfo = userAssets as Ref<UserAsset>;
	const menuListRef = ref<any>(null);
	// const plans = {
	// 	free: {
	// 		title: 'Free',
	// 		color: '#A4BBD9'
	// 	},
	// 	lite: {
	// 		title: 'Lite',
	// 		color: '#537AE1'
	// 	},
	// 	plus: {
	// 		title: 'Plus',
	// 		color: '#797BDC'
	// 	},
	// 	ultra: {
	// 		title: 'Ultra',
	// 		color: '#D8A34C'
	// 	}
	// };
	const handleShowMenu = () => {
		menuListRef.value.open();
	};

	function scrollEvent () {
		if (window.scrollY > 100) {
			showBackground.value = true;
			headerTop.value = '-1px';
		} else {
			showBackground.value = false;
			headerTop.value = '24px';
		}
	}
	const config = useNuxtApp().$arvinConfig as any;
	const consoleUrl = ref('');
	const token = ref('');
	const arvinLogoUrl = ref('');

	const loginPath = ref('');

	onMounted(() => {
		user.value = getUserInfo();
		token.value = getArvinSessionToken();
		arvinLogoUrl.value = config.arvinLogoUrl;
		// console.log(user.value, token.value, 'the-header');

		if (['admin', 'super'].includes(user.value.role)) {
			consoleUrl.value = `${config.consoleUrl}?token=${token.value}`;
		}
		if (!isMobile.value) {
			window && window.addEventListener('scroll', scrollEvent);
		}
		loginPath.value = `/user/login?next=${route.path}${window.location.search}`;
	});

	onBeforeUnmount(() => {
		if (!isMobile.value) {
			window.removeEventListener('scroll', scrollEvent);
		}
	});

	const linkReport = (title: string) => {
		reportEvent('Menu_Link_Click', {
			entry_type: 'web-navigationBar',
			function_type: title
		});
	};

	const menuHoverStatus = ref([]);

	function onMenuVisibleChange (index: number, status: boolean) {
		// @ts-ignore
		menuHoverStatus.value[index] = status;
	}
	function handleLogin () {
		navigateTo(loginPath.value);
	}

	const openPromptUpdated = () => {
		ElMessageBox.prompt('You can view and tune the prompt words later', 'Tip', {
			confirmButtonText: 'OK',
			cancelButtonText: 'Cancel',
			inputPlaceholder: getArvinSessionToken()
		})
			.then(({ value }) => {
				let url = `https://arvin.dev.eks-089.atcloudbox.com/internal/admin/chat_prompts?group_id=1&x_arvin_authorization=`;
				window.open(url + getArvinSessionToken(), '_blank');
			});
	};
</script>

<style lang="less">
.the-header {
  position: sticky;
  top: v-bind(headerTop);
  max-width: 1000px;
  margin: 0 auto;
  backdrop-filter: blur(50px);
  transition: top 0.3s ease;

  .menu-group {
    .el-dropdown {
      line-height: 16px;
      font-size: 16px;
    }

    .el-dropdown-link {
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      color: #282A37;
      // padding: 12px;
      border-radius: 8px;
      display: inline-flex;
      align-items: center;
      gap: 4px;

      &:focus-visible {
        outline: none;
      }

      &.is-dark {
        @apply c-white hover:bg-primary hover:c-white;
      }

      &.active,
      &:hover {
        // background-color: #F3F3FF;
        @apply c-primary;
      }

      &.dark-active {
        @apply bg-primary c-white;
      }
    }

  }
}

.dropdown-menu-box {
  // background: rgba(255, 255, 255, 0.4);
  // backdrop-filter: blur(150px);
  a {
    text-decoration: none;
    color: #282A37;
    line-height: 24px;

    &:hover {
      color: var(--primary-color);
    }
  }
}

.el-dropdown__popper.el-popper {
  // background: rgba(255, 255, 255, 0.4);
  // backdrop-filter: blur(150px);
  border: 1px solid #EBEDF3;
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.el-dropdown__popper .el-dropdown-menu{
  // padding: 0 !important;
  // background: transparent !important;
}
.el-popper__arrow {
  display: none !important;
}
</style>
