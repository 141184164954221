<template>
  <div class="home-container pt-64px">
    <SafeAreaContainer>
      <section class="relative z-2">
        <h1 class="text-center fs-36 md:fs-56 font-800 c-#282A37">All-in-one <span class="c-primary">AI Assistant</span>
          <div class="text-center">Start with Arvin</div>
        </h1>
        <div class="text-center fs-12 md:fs-16 mt-26px max-w-680px mx-auto">
          Create professional logos and signatures just with 1-click in the Arvin Logo, and additionally chat, write, and translate with AI in the webapp.
        </div>
        <div class="flex-center mt-26px gap-12px">
          <NuxtLink to="/en/home" external class="white-btn lt-md:fs-14 w-160px h-48px md:w-240px md:h-62px" @click="reportHomeEvent('webapp', 'first_screen_btn')">
            Arvin Webapp
          </NuxtLink>
          <NuxtLink :to="arvinLogoUrl" class="black-btn lt-md:fs-14 w-160px h-48px md:w-240px md:h-62px" @click="reportHomeEvent('logo', 'first_screen_btn')">
            Arvin Logo
          </NuxtLink>
        </div>
        <div class="flex-center mt-60px mb-20px">
          <img class="h-48px" src="/imgs/home/score.png" alt="">
        </div>
        <div class="relative z-2">
          <nuxt-link to="/en/home" external class="max-w-90% inline-block hover:scale-102 transition-all duration-300" @click="reportHomeEvent('webapp', 'first_screen_btn')">
            <img class="max-w-full md:h-432px" src="/imgs/home/bg1.png" alt="">
          </nuxt-link>
          <nuxt-link class="lt-md:max-w-80% inline-block absolute right-0 bottom-0 hover:scale-102 transition-all duration-300" :to="arvinLogoUrl" @click="reportHomeEvent('logo', 'first_screen_btn')">
            <img class="max-w-full md:h-380px" src="/imgs/home/bg2.png" alt="">
          </nuxt-link>
        </div>
        <div class="banner-container overflow-hidden relative mt-30px">
          <div ref="bannerRef" class="banner-scroll flex items-center">
            <img class="h-48px" src="/imgs/home/banner.png" alt="">
            <img class="h-48px" src="/imgs/home/banner.png" alt="">
          </div>
          <div class="banner-mask-left"></div>
          <div class="banner-mask-right"></div>
        </div>
      </section>
      <div class="mt-80px md:mt-120px">
        <div class="text-center c-#282A37 fs-32 md:fs-48 font-600 mb-48px md:mb-60px">Discover all of Arvin's products</div>
        <HomeProductCard :type="!isMobile ? 'larger' : 'normal'">
          <div class="w-full h-full flex md:flex-row lt-md:flex-col-reverse md:pl-36px">
            <div class="flex flex-col justify-center md:w-360px md:mr-56px lt-md:px-36px lt-md:pt-25px lt-md:pb-36px">
              <div class="fs-18 md:fs-24 font-600 c-#282A37">Arvin Logo</div>
              <div class="fs-14 c-#616478 mt-12px mb-16px">Design professional logos, signatures, even business cards just in one click.</div>
              <NuxtLink :to="arvinLogoUrl" class="white-btn lt-md:fs-14 w-290px lt-md:mx-auto  md:w-233px h-48px" @click="reportHomeEvent('logo', 'second_screen_btn')">Try on the web</NuxtLink>
            </div>
            <img class="md:h-320px lt-md:w-full block mx-auto" src="/imgs/home/product1.png" alt="">
          </div>
        </HomeProductCard>
        <div class="mt-36px md:mt-24px md:flex gap-24px">
          <HomeProductCard class="flex-1">
            <div class="w-full h-full">
              <img class="md:h-320px lt-md:w-full block mx-auto" src="/imgs/home/product2.png" alt="">
              <div class="flex flex-col justify-center px-36px pt-25px pb-36px">
                <div class="fs-18 md:fs-24 font-600 c-#282A37">Arvin Webapp</div>
                <div class="fs-14 c-#616478 mt-12px mb-16px">Experience Al chat, compose, translation to solve any problems.</div>
                <NuxtLink to="/en/home" external class="white-btn lt-md:fs-14 w-290px lt-md:mx-auto md:w-233px h-48px" @click="reportHomeEvent('webapp', 'second_screen_btn')">Try on the web</NuxtLink>
              </div>
            </div>
          </HomeProductCard>
          <HomeProductCard class="flex-1 lt-md:mt-36px">
            <div class="w-full h-full">
              <img class="md:h-320px lt-md:w-full block mx-auto" src="/imgs/home/product3.png" alt="">
              <div class="flex flex-col justify-center px-36px pt-25px pb-36px">
                <div class="fs-18 md:fs-24 font-600 c-#282A37">{{ browser === 'Edge' ? 'Edge' : 'Chrome' }} Plugin</div>
                <div class="fs-14 c-#616478 mt-12px mb-16px">Explore AI summary, web translation in the sidebar or toolbar.</div>
                <HomeDownloadPlugin customClass="lt-md:mx-auto lt-md:fs-14 lt-md:w-290px" @download="reportHomeEvent('plugin', 'second_screen_btn')" />
              </div>
            </div>
          </HomeProductCard>
        </div>
      </div>
      <section class="mt-80px md:mt-120px">
        <template v-for="(item, index) in features" :key="index">
          <div
            class="flex flex-col-reverse lg:flex-row items-center gap-24px md:gap-60px mb-80px md:mb-120px last:mb-0"
            :class="index%2 ? 'lg:flex-row-reverse' : ''">
            <div class="flex-1">
              <h3 class="c-deep-90 fs-24 md:fs-36 lt-md:text-center">{{ item.title }}</h3>
              <div class="c-deep-70 fs-14 md:fs-16 my-24px lt-md:text-center">
                <p v-for="(text, idx) in item.subTitle" :key="idx" :class="idx !== item.subTitle.length - 1 ? 'mb-12px' : ''">
                  {{ text }}
                </p>
              </div>
              <NuxtLink
                :to="item.link"
                external
                class="black-btn lt-md:fs-14 lt-md:mx-auto w-290px h-36px md:w-233px h-48px"
                @click="reportHomeEvent(item.type, 'third_screen_btn')"
              >{{ item.btnName || 'Try Arvin Now' }}
              </NuxtLink>
            </div>
            <div class="flex-[2] max-w-764px rounded-16px overflow-hidden">
              <nuxt-img
                provider="twicpics" loading="lazy"
                alt="arvin" :src="item.img"
                class="w-full object-cover rounded-16px"/>
            </div>
          </div>
        </template>
      </section>
      <section>
        <h2 class="fs-32 md:fs-48">More interesting tools</h2>
        <div
          class="max-w-1200px mx-auto mt-24px grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12px sm:gap-24px">
          <nuxt-link
            v-for="(item, index) in moreTools" :key="index"
            :to="item.link"
            :class="{ 'hover:bg-#F3F3FF cursor-pointer': item.link }"
            class="w-full bg-#fff rounded-16px flex-column-center p-12px sm:p-24px decoration-none">
            <div class="w-48px h-48px bg-#f7f8fa rounded-16px flex-center mb-8px ">
              <svg-icon :name="item.icon" size="24" class="c-#444658"></svg-icon>
            </div>
            <h3 class="fs-14 sm:fs-18 c-#444658 font-600 mb-6px sm:mb-12px">{{ item.name }}</h3>
            <p class="fs-12 sm:fs-14 leading-20px c-#828499 text-center">{{ item.desc }}</p>
          </nuxt-link>
          <div class="bg-#f3f3ff w-full rounded-16px flex-column-center p-24px">
            <div class="w-48px h-48px bg-#f7f8fa rounded-16px flex-center mb-8px ">
              <svg-icon name="code2" size="24" class="c-#444658"></svg-icon>
            </div>
            <p class="fs-14 md:fs-18 font-600 c-#444658 text-center">More features are being developed...</p>
          </div>
        </div>
      </section>
      <section>
        <h2 class="mb-16px! pb-0! fs-32 md:fs-48">Real User Reviews</h2>
        <div class="c-#828499 fs-16 text-center mb-48px md:mb-60px">Arvin is used by users all over the world</div>
        <HomeUserReview />
      </section>
      <section flex-column-center>
        <h2 class="mb-20px! fs-32 md:fs-48">Frequently Asked Questions</h2>
        <div class="w-full max-w-840px">
          <NewFAQ title="" :list="FAQList">
          </NewFAQ>
        </div>
      </section>
      <FooterAdv/>
    </SafeAreaContainer>
    <BackToTop />
    <el-dialog v-model="showVideoPopup" class="video-popup" width="65%" :destroy-on-close="true">
      <iframe
        class="rounded-16px overflow-hidden aspect-16/9" width="100%"
        height="100%"
        src="https://www.youtube.com/embed/tqRDYhHYCco?si=zwvvj4qncHzYyjZ5&autoplay=1"
        title="Arvin - Your AI Writing Assistant & Best AI Content Generator" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
      <div
        class="hidden sm:flex w-40px h-40px rounded-40px flex-center absolute top--46px right--46px cp"
        border="1px solid white"
        @click="showVideoPopup = false">
        <nuxt-icon name="close" class="c-white fs-24"></nuxt-icon>
      </div>
    </el-dialog>
  </div>
</template>
<script setup lang="ts">
	import useFromTo from '@/composables/useFromTo';
	const config = useNuxtApp().$arvinConfig as any;
	const title = 'Arvin AI - Best ChatGPT Chrome Extension Powered by GPT-4o ';
	const description = 'Arvin AI: A free ChatGPT Chrome extension powered by GPT-4o, offering you AI assistant for AI art, summaries, and content creation. Try now! ';
	const keywords = 'arvin ai, tryarvin ai, arvin ai art generator';

	const isMobile = useIsMobile();
	const browser = getBrowserName();
	useFromTo();
	useSeoMeta({
		title,
		keywords,
		ogTitle: title,
		description,
		ogDescription: description,
		cluster: 'Home',
		keyword: 'home'
	});
	const arvinLogoUrl = ref('');

	const showVideoPopup = ref(false);
	const features = computed(() => [
		{
			title: 'Professional AI Logo Design',
			subTitle: [
				'One-click generate logo, signatures, and business cards.',
				'Change logos from thousands of templates.',
				'Real-time mockups of designs in real-world scenarios.'
			],
			type: 'logo',
			img: '/imgs/home/feature1.png',
			btnName: 'Try Arvin Logo Now',
			link: arvinLogoUrl.value
		},
		{
			title: 'Universal AI Chatbots',
			subTitle: [
				'Chat with GPT-4o, Claude 3.5 Sonnet, etc.',
				'Chat with Arvin about uploaded PDFs & images.',
				'Endlessly solve any of your questions.'
			],
			type: 'webapp',
			img: '/imgs/home/feature2.png',
			link: '/en/home'
		},
		{
			title: 'Smart AI writers',
			subTitle: [
				'Automatically generate articles & emails with one click.',
				'Customize the style of email replies.',
				'Multiple types of templates such as blessing cards, etc.'
			],
			type: 'webapp',
			img: '/imgs/home/feature3.png',
			link: '/en/home'
		}
	]);
	const moreTools = [
		{
			name: 'Chat with AI',
			desc: 'Get the fastest GPTs responses',
			link: '/feature/how-to-access-gpt-4',
			icon: 'chat'
		},
		{
			name: 'AI Writer',
			desc: 'Quickly generate articles, emails, blogs, etc.',
			link: '/feature/ai-article-writer',
			icon: 'write'
		},
		{
			name: 'AI Image Generator',
			desc: 'Generate pictures in various styles with one click',
			link: '/feature/ai-painter',
			icon: 'brush'
		},
		{
			name: 'Data Analysis',
			desc: 'Complex data and coding processing through conversation',
			link: '/arvin-code-interpreter',
			icon: 'explain-code'
		},
		{
			name: 'Chat with PDF',
			desc: 'Summarize PDF content precisely',
			link: '',
			icon: 'pdf'
		},
		{
			name: 'Email Reply',
			desc: 'Generate multiple styles of email responses',
			link: '/feature/ai-email-response-generator',
			icon: 'message'
		},
		{
			name: 'Youtube Summary',
			desc: 'Generate YouTube summary online for free',
			link: '',
			icon: 'youtube'
		}, {
			name: 'Area Summary',
			desc: 'Summarize the content of each area of the web page online',
			link: '',
			icon: 'website'
		}, {
			name: 'Background Remover',
			desc: 'Remove image backgrounds automatically',
			link: '/image-tools/background-remover',
			icon: 'bg-remover'
		},
		{
			name: 'Grammar Checker',
			desc: 'AI Grammar Checker at your fingertips',
			link: '/feature/ai-grammar-checker',
			icon: 'grammar'
		},
		{
			name: 'Writing Improver',
			desc: 'Polish Your Text Smoothly',
			link: '/feature/writing-improver',
			icon: 'pen'
		},
		{
			name: 'Web Translator',
			desc: 'Instantly translate any webpage',
			link: '/feature/web-translator',
			icon: 'translate'
		},

		{
			name: 'PDF to Word',
			desc: 'Convert your PDF to Word',
			link: '/PDF-tools/pdf-to-word',
			icon: 'word'
		},
		{
			name: 'PDF to PNG',
			desc: 'Convert your PDF to PNG',
			link: '/PDF-tools/pdf-to-png',
			icon: 'png'
		},
		{
			name: 'PDF to JPG',
			desc: 'Convert your PDF to JPG',
			link: '/PDF-tools/pdf-to-jpg',
			icon: 'jpg'
		}
	];

	const FAQList = [
		{ q: 'What is Arvin?', a: 'Arvin is your AI-powered ChatGPT assistant, a browser extension that saves time and money. It offers ChatGPT responses on Google, summarizes YouTube, blogs, and docs, and interacts on LinkedIn, Twitter, and Gmail. It translates into 25+ languages.' },
		{ q: 'How does Arvin work?', a: 'Arvin, powered by ChatGPT API, responds to chat, creates copy from templates, translates, rephrases, and explains web text.' },
		{ q: 'How to use Arvin?', a: "Once added to Chrome, open Arvin with Alt/Option + A. On sites like Twitter, LinkedIn, Youtube, and Gmail, you'll find Arvin buttons for easy access." },
		{ q: 'Is Arvin free?', a: 'Yes, Arvin is free and safe. Users receive 15 daily credits that can be used for various services including GPT 3.5, GPT-4o, YouTube Summarizer, web summarizer, PDF summarizer, and more.' },
		{
			q: 'Can I have a refund?', a: `For those who have signed up for a paid plan and wish to initiate a refund request, please <a href="https://arvin-web.zendesk.com/hc/en-us/requests/new" target="_blank" class="c-primary">contact us</a>. Please note that refunds may take 5-10 business days to be processed.</br>
      </br>
      You are eligible for a refund only if you meet ALL of the following conditions simultaneously (for subscription types with a trial period, counting starts from the beginning of the trial):</br>
      </br>
      1. Your subscription period does not exceed 7 days；</br>
      2. You have not used advanced models more than 30 times；</br>
      3. You have not used drawing/image tools more than 10 times.</br>
      </br>
      We will review your actual usage to determine if you qualify for a refund. To prevent malicious refund requests, we reserve the right to decline refunds at our discretion.`
		}
		// { q: 'Do I need a ChatGPT account to use Arvin?', a: "No, a ChatGPT account isn't needed. You can create a free account ", link: '/user/dashboard' },
		// { q: 'Which search engine is supported by Arvin?', a: 'Arvin supports Google, Baidu, Bing, DuckDuckGo, Yahoo, Yandex, Ask, Naver, and Ecosia.' }
	];

	const reportHomeEvent = (functionType: string, entryType: string) => {
		reportEvent('Web_Homepage_Product_Click', {
			function_type: functionType,
			entry_type: entryType
		});
	};

	const bannerRef = ref<HTMLElement | null>(null);
	let animationId: number;
	let scrollPosition = 0;

	const animateBanner = () => {
		if (!bannerRef.value) { return; }

		scrollPosition += 0.5;
		if (scrollPosition >= bannerRef.value.scrollWidth / 2) {
			scrollPosition = 0;
		}

		bannerRef.value.style.transform = `translateX(-${scrollPosition}px)`;
		animationId = requestAnimationFrame(animateBanner);
	};

	onMounted(() => {
		arvinLogoUrl.value = config.arvinLogoUrl;
		reportEvent('Web_Homepage_Show');
		animationId = requestAnimationFrame(animateBanner);
	});

	onBeforeUnmount(() => {
		if (animationId) {
			cancelAnimationFrame(animationId);
		}
	});

</script>
<style lang="less" scoped>
.home-container {

  .first-screen-animation {
    img {
      border-radius: 16px;
      box-shadow: 19px 24px 40px 0px rgba(86, 86, 146, 0.1);

      &.last-one {
        box-shadow: 19px 24px 80px 0px rgba(86, 86, 146, 0.1);
      }
    }
  }

  .backdrop {
    backdrop-filter: blur(60px);
    background-color: #fff9;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    padding: 20px 0;
    color: #282A37;
    @apply sm:text-48px sm:mb-40px;
  }

  section {
    @apply mb-80px lg:150px;
  }

  .play-btn-outer {
    background: radial-gradient(#7c61cd 30%, #7c61cd 100%);
    transform-origin: center center;
    animation: play-btn-pulse-outer 4s ease-out infinite;
  }

  .play-btn-inner {
    background: radial-gradient(#7c61cd 30%, #7c61cd 100%);
    transform-origin: center center;
    animation: play-btn-pulse-inner 4s ease-out infinite;
  }

  .play-animation {
    transform-origin: center center;
    animation: play-btn-pulse 4s ease-out infinite;
  }

  @keyframes play-btn-pulse {

    0%,
    10%,
    to {
      transform: scale(.95)
    }

    30% {
      transform: scale(1)
    }
  }
}

@keyframes play-btn-pulse-inner {

  0%,
  30% {
    opacity: .3;
    transform: scale(1)
  }

  to {
    opacity: 0;
    transform: scale(1.8)
  }
}

@keyframes play-btn-pulse-outer {
  0% {
    opacity: .3;
    transform: scale(1)
  }

  80%,
  to {
    opacity: 0;
    transform: scale(2)
  }
}

.banner-container {
  width: 100%;

  .banner-mask-left,
  .banner-mask-right {
    position: absolute;
    top: 0;
    width: 150px;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }

  .banner-mask-left {
    left: 0;
    background: linear-gradient(to right, #F8F8FC 0%, rgba(255, 255, 255, 0) 100%);
  }

  .banner-mask-right {
    right: 0;
    background: linear-gradient(to left, #F8F8FC 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.banner-scroll {
  will-change: transform;
}
</style>
