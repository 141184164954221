import type { Config } from './types';
export default {
  env: '_develop',
  storageKey: 'amb_storage_dev_',
  homepageUrl: 'https://arvin.dev.eks-089.atcloudbox.com',
  baseUrl: 'https://trkjyuarj5.server.bytepower.app/bp/',
  loginUrl: 'https://arvin.dev.eks-089.atcloudbox.com',
  payUrl: 'https://arvin.dev.eks-089.atcloudbox.com/user/subscription',
  termsOfService: 'https://arvin.dev.eks-089.atcloudbox.com/terms-of-service',
  privacyPolicy: 'https://arvin.dev.eks-089.atcloudbox.com/privacy-policy',
  creditRuleUrl: 'https://arvin.dev.eks-089.atcloudbox.com/help/credit-deduction-rule',
  dashboardUrl: 'https://arvin.dev.eks-089.atcloudbox.com/user/dashboard',
  settingsUrl: 'https://arvin.dev.eks-089.atcloudbox.com/user/settings',
  casApiUrl: 'https://caspian-data.tryarvin.com/caspian/',
  billingUrl: 'https://arvin.dev.eks-089.atcloudbox.com/user/billing',
  i: '4db65fbdf10049f5',
  k: '68e10bc26c714e258ca5a8e050aee6e4',
  a: 'APP3V4XASIBNWEDI',
  summaryServerUrl: 'http://netopenai.debug.bytepowerapp.cn',
  feedbackUrl: 'https://arvin.chat/feedback/',
  arvinServerUrl: 'https://arvin.dev.eks-089.atcloudbox.com',
  // arvinServerUrl: 'http://192.168.1.20:8000/',
  language: 'en',
  version: 1,
  msalClientId: '3ee3b409-ce8f-4553-9654-74c13106d128',
  msalAuthority: 'https://login.microsoftonline.com/common',
  msalRedirectUrl: 'http://localhost:3000/user/login',
  bpMSLoginUrl: 'https://arvin.dev.eks-089.atcloudbox.com/api/users/microsoft',
  googleMeasurementId: 'G-4G9D85WZXT',
  consoleUrl: 'https://arvin.dev.eks-089.atcloudbox.com/console/arvinlogin',
  contactUrl: 'https://arvin-web.zendesk.com/hc/en-us/requests/new',
  arvinLogoUrl: 'https://logo.dev.eks-089.atcloudbox.com'
} as Config;
