<template>
  <div>
    <slot>
      <h2 v-if="title">{{ title }}</h2>
    </slot>
    <el-collapse v-model="activeItem" class="my-collapse max-w-840px mx-auto" accordion>
      <el-collapse-item v-for="(item, index) in list" :key="index" class="my-collapse-item" :title="item.q" :name="index">
        <template #title>
          <h3 class="font-500 fs-18 md:fs-24 font-600 leading-tight text-left">{{ item.q }}</h3>
        </template>
        <a v-if="item.link" :href="item.link">{{ item.link }}</a>
        <div v-if="!isArray(item.a)" v-html="item.a"></div>
        <div v-else>
          <p v-for="(_item,i) in item.a" :key="i" class="min-h-20px + pt-8px">{{ _item }}</p>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script setup lang="ts">
	import { isArray } from 'element-plus/es/utils';

	interface FAQData {
		q: string;
		a: string | string[];
		link?: string;
	}

	defineProps({
		title: {
			type: String,
			default: 'Frequently Asked Questions'
		},
		list: {
			type: Array as PropType<FAQData[]>,
			default: [] as FAQData[]
		}
	});

	const activeItem = ref(0);

</script>

<style lang="less" scoped>
h2 {
	color: #282a37;
	font-size: 32px;
	font-weight: 600;
	text-align: center;
	padding: 20px 0;
	@apply lg:text-48px;
}

.my-collapse {
	border-top: 0;
	border-bottom: 0;
}

.my-collapse-item {
	:deep(.el-collapse-item__header) {
		background-color: transparent;
		color: #444658;
		border-bottom-color: #EBEDF3;
		font-size: 17px;
		line-height: 20px;
		height: auto;
		// line-height: 70px;
		// height: 70px;
		padding: 30px 0;
		font-weight: 500;
		align-items: flex-start;
		@apply lg:text-24px lg:py-36px;

		&.is-active {
			border-bottom-color: transparent;
		}
		.el-collapse-item__arrow {
			padding-top: 6px;
		}
	}

	:deep(.el-collapse-item__wrap) {
		background-color: transparent;
		// border-bottom-color: #444658;

		.el-collapse-item__content {
			color: #616478;
			font-size: 16px;
			line-height: 20px;
			@apply lg:leading-24px lg:pb-36px;
		}
	}
}
</style>
