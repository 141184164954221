import { before } from 'node:test';
import { getArvinSessionToken, getDeviceId } from './useUtils';
import { getUserProfile, getUserAsset } from '~/apis/user';
import { useFeatCostStore } from '~/store/featCost';

const user = ref<User | null>(null);
const token = ref<string | null>(null);
export function useInitApp () {
  // onMounted need to parent
  const route = useRoute();
  // onMounted(() => {
    // const from = window.localStorage.getItem('login_from');
    // if (from === 'extension' && token.value) {
    //   window.localStorage.removeItem('login_from');
    //   window.close();
    // }
  // });

  onBeforeMount(() => {
    user.value = getUserInfo();
    token.value = getArvinSessionToken();
    if (route.query.for && route.query.for === 'signOut') {
      logout(user.value);
    }
    checkLandingPage();
    initApp();
  });

  /**
   * Checks if the landing page key exists in the local storage. If it does not exist, it sets the landing page key
   * to the current URL in the local storage.
   */
  const checkLandingPage = () => {
    const LANDING_PAGE_KEY = 'landing_page';
    localStorage.getItem(LANDING_PAGE_KEY) || localStorage.setItem(LANDING_PAGE_KEY, location.href);
  };

  const initApp = async () => {
    user.value = getUserInfo();
    token.value = getArvinSessionToken();
    const from = route.query.from as string;
    if (from) {
			window.localStorage.setItem('login_from', from);
		}
    const tokenData = useCookie('arvin-token') as Ref<string>;
    if (!(token.value || tokenData.value) || route.query.for === 'signOut') {
      return;
    }
    const userInfo = getUserInfo();
    const needUpdateUserInfo = tokenData && !userInfo.userId;
    if (userInfo.userId && !userInfo.register_time || needUpdateUserInfo) {
      await setUserInfo({
        userArvinToken: tokenData.value
      });
      const res = await getUserProfile();
      user.value = {
        ...userInfo,
        ...res,
        userId: res.user_id || userInfo.userId,
        userName: res.user_name,
        avatarUrl: res.avatar,
        userArvinToken: token.value || tokenData
      };
      await setUserInfo(user.value);
      if (needUpdateUserInfo) {
        window.location.reload();
      }
    }

    const featCostStore = useFeatCostStore();
    nextTick(() => {
      featCostStore.getAssets();
      featCostStore.getFeatCost();
    });
    getDeviceId();
  };
  return {
    initApp
  };
}

export function useAuthInit () {
  onBeforeMount(() => {
    const token = getArvinSessionToken();
    if (!token) {
      console.warn('DO --useAuthInit-- auth', token);
      navigateTo('/user/login');
    }
  });
}
