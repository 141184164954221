import { default as about83x4jfq8wKMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/about.vue?macro=true";
import { default as indexlVUHJ2kb94Meta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools-blog/index.vue?macro=true";
import { default as _91lang_93_45grammar_45checker66gvw9rjGQMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/[lang]-grammar-checker.vue?macro=true";
import { default as _91slug_93bWQWWNvyRuMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/[slug].vue?macro=true";
import { default as birthday_45wishes_45creatorRWFTZ9hjIQMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/birthday-wishes-creator.vue?macro=true";
import { default as dataQ0qVbk7mKGMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/data.ts?macro=true";
import { default as indexb0bU7BdJMiMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/index.vue?macro=true";
import { default as instagram_45bio_45generatorFYz8jWBd9dMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/instagram-bio-generator.vue?macro=true";
import { default as instagram_45caption_45generatorzOow4agkJTMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/instagram-caption-generator.vue?macro=true";
import { default as datamSdIIa9dAXMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/arvin-code-interpreter/data.ts?macro=true";
import { default as indexGl2NHtPJkzMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/arvin-code-interpreter/index.vue?macro=true";
import { default as arvin_45instructionOydWRfdEnYMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/arvin-instruction.vue?macro=true";
import { default as auth_45landingRdNw2AGf1YMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/auth-landing.vue?macro=true";
import { default as _91name_93mAbDUwc4KWMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/best-gpts/arvin-gpts/[name].vue?macro=true";
import { default as indexDZWyRtmLvnMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/best-gpts/arvin-gpts/index.vue?macro=true";
import { default as _91id_937gVGLiLeDeMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/chatgpt-prompt-generator/[path]/[id].vue?macro=true";
import { default as indexu6Zs4k2FsbMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/chatgpt-prompt-generator/[path]/index.vue?macro=true";
import { default as dataMgP74CPFAYMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/chatgpt-prompt-generator/data.ts?macro=true";
import { default as indexroQr2L2BPvMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/chatgpt-prompt-generator/index.vue?macro=true";
import { default as indexdlsQ16DQ8vMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/code-interpreter/index.vue?macro=true";
import { default as contact7W4kzReuOHMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/contact.vue?macro=true";
import { default as cookiese17DNDecxYMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/cookies.vue?macro=true";
import { default as ai_45article_45writer1rsLuLaUe0Meta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-article-writer.vue?macro=true";
import { default as ai_45email_45response_45generator9193tNQTTlMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-email-response-generator.vue?macro=true";
import { default as ai_45grammar_45checkerNRRxrsGWd5Meta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-grammar-checker.vue?macro=true";
import { default as ai_45logo_45designerfli4FS4lZWMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-logo-designer.vue?macro=true";
import { default as ai_45painterdIY2D8QPo2Meta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-painter.vue?macro=true";
import { default as ai_45signature_45generatordsQeDmmbDRMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-signature-generator.vue?macro=true";
import { default as how_45to_45access_45gpt_454oiJ76rkRNKMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/how-to-access-gpt-4.vue?macro=true";
import { default as web_45translatorb474fGF6zJMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/web-translator.vue?macro=true";
import { default as writing_45improverSNc8mmAZsrMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/writing-improver.vue?macro=true";
import { default as feedbacklifjFfzv0GMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feedback.vue?macro=true";
import { default as credit_45deduction_45ruleXsCjFL5jkuMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/help/credit-deduction-rule.vue?macro=true";
import { default as indexhnNeJ0kkEdMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/image-tools/[path]/index.vue?macro=true";
import { default as image7wytoUvZH4Meta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/image-tools/image.vue?macro=true";
import { default as indexa4oJHZcZybMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/image-tools/index.vue?macro=true";
import { default as indexM0pZeEN8pUMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/index.vue?macro=true";
import { default as emptyQRE4YytTdHMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/invitation/empty.vue?macro=true";
import { default as indexfdp0H28hMFMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/invitation/index.vue?macro=true";
import { default as InviteCopyUrlluzkGieyyDMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/invitation/InviteCopyUrl.vue?macro=true";
import { default as invite79S0q4iu2JMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/invite.vue?macro=true";
import { default as dataWxhnD6953VMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/PDF-tools/data.ts?macro=true";
import { default as pdf_45to_45_91type_93XjCflYj8ZDMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/PDF-tools/pdf-to-[type].vue?macro=true";
import { default as privacy_45policyuVoE9hW38FMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/privacy-policy.vue?macro=true";
import { default as indexsk3k9xhh4wMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/search/index.vue?macro=true";
import { default as _91id_93sjJPI0zAWwMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/search/result/[id].vue?macro=true";
import { default as resultLhP1nPJe3UMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/search/result.vue?macro=true";
import { default as _91id_93JiLYkZER9SMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/share/chat/[id].vue?macro=true";
import { default as terms_45of_45serviceQlgjd43P1ZMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/terms-of-service.vue?macro=true";
import { default as _91id_93JUTVpPttFdMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/[id].vue?macro=true";
import { default as guidet0RuBHWKfuMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/guide.vue?macro=true";
import { default as instructionDjYi7glQ70Meta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/instruction.vue?macro=true";
import { default as loginNGeL9ogsoVMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/login.vue?macro=true";
import { default as reset_45password2hY2w7avtkMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/reset-password.vue?macro=true";
import { default as subscriptioncm3QrxeMuzMeta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/subscription.vue?macro=true";
import { default as welcomegwO4INugt2Meta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/welcome.vue?macro=true";
import { default as webstoreLMloLGwxM4Meta } from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/webstore.vue?macro=true";
export default [
  {
    name: about83x4jfq8wKMeta?.name ?? "about",
    path: about83x4jfq8wKMeta?.path ?? "/about",
    meta: about83x4jfq8wKMeta || {},
    alias: about83x4jfq8wKMeta?.alias || [],
    redirect: about83x4jfq8wKMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexlVUHJ2kb94Meta?.name ?? "ai-tools-blog",
    path: indexlVUHJ2kb94Meta?.path ?? "/ai-tools-blog",
    meta: indexlVUHJ2kb94Meta || {},
    alias: indexlVUHJ2kb94Meta?.alias || [],
    redirect: indexlVUHJ2kb94Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools-blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91lang_93_45grammar_45checker66gvw9rjGQMeta?.name ?? "ai-tools-lang-grammar-checker",
    path: _91lang_93_45grammar_45checker66gvw9rjGQMeta?.path ?? "/ai-tools/:lang()-grammar-checker",
    meta: _91lang_93_45grammar_45checker66gvw9rjGQMeta || {},
    alias: _91lang_93_45grammar_45checker66gvw9rjGQMeta?.alias || [],
    redirect: _91lang_93_45grammar_45checker66gvw9rjGQMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/[lang]-grammar-checker.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bWQWWNvyRuMeta?.name ?? "ai-tools-slug",
    path: _91slug_93bWQWWNvyRuMeta?.path ?? "/ai-tools/:slug()",
    meta: _91slug_93bWQWWNvyRuMeta || {},
    alias: _91slug_93bWQWWNvyRuMeta?.alias || [],
    redirect: _91slug_93bWQWWNvyRuMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/[slug].vue").then(m => m.default || m)
  },
  {
    name: birthday_45wishes_45creatorRWFTZ9hjIQMeta?.name ?? "ai-tools-birthday-wishes-creator",
    path: birthday_45wishes_45creatorRWFTZ9hjIQMeta?.path ?? "/ai-tools/birthday-wishes-creator",
    meta: birthday_45wishes_45creatorRWFTZ9hjIQMeta || {},
    alias: birthday_45wishes_45creatorRWFTZ9hjIQMeta?.alias || [],
    redirect: birthday_45wishes_45creatorRWFTZ9hjIQMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/birthday-wishes-creator.vue").then(m => m.default || m)
  },
  {
    name: dataQ0qVbk7mKGMeta?.name ?? "ai-tools-data",
    path: dataQ0qVbk7mKGMeta?.path ?? "/ai-tools/data",
    meta: dataQ0qVbk7mKGMeta || {},
    alias: dataQ0qVbk7mKGMeta?.alias || [],
    redirect: dataQ0qVbk7mKGMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/data.ts").then(m => m.default || m)
  },
  {
    name: indexb0bU7BdJMiMeta?.name ?? "ai-tools",
    path: indexb0bU7BdJMiMeta?.path ?? "/ai-tools",
    meta: indexb0bU7BdJMiMeta || {},
    alias: indexb0bU7BdJMiMeta?.alias || [],
    redirect: indexb0bU7BdJMiMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/index.vue").then(m => m.default || m)
  },
  {
    name: instagram_45bio_45generatorFYz8jWBd9dMeta?.name ?? "ai-tools-instagram-bio-generator",
    path: instagram_45bio_45generatorFYz8jWBd9dMeta?.path ?? "/ai-tools/instagram-bio-generator",
    meta: instagram_45bio_45generatorFYz8jWBd9dMeta || {},
    alias: instagram_45bio_45generatorFYz8jWBd9dMeta?.alias || [],
    redirect: instagram_45bio_45generatorFYz8jWBd9dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/instagram-bio-generator.vue").then(m => m.default || m)
  },
  {
    name: instagram_45caption_45generatorzOow4agkJTMeta?.name ?? "ai-tools-instagram-caption-generator",
    path: instagram_45caption_45generatorzOow4agkJTMeta?.path ?? "/ai-tools/instagram-caption-generator",
    meta: instagram_45caption_45generatorzOow4agkJTMeta || {},
    alias: instagram_45caption_45generatorzOow4agkJTMeta?.alias || [],
    redirect: instagram_45caption_45generatorzOow4agkJTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/ai-tools/instagram-caption-generator.vue").then(m => m.default || m)
  },
  {
    name: datamSdIIa9dAXMeta?.name ?? "arvin-code-interpreter-data",
    path: datamSdIIa9dAXMeta?.path ?? "/arvin-code-interpreter/data",
    meta: datamSdIIa9dAXMeta || {},
    alias: datamSdIIa9dAXMeta?.alias || [],
    redirect: datamSdIIa9dAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/arvin-code-interpreter/data.ts").then(m => m.default || m)
  },
  {
    name: indexGl2NHtPJkzMeta?.name ?? "arvin-code-interpreter",
    path: indexGl2NHtPJkzMeta?.path ?? "/arvin-code-interpreter",
    meta: indexGl2NHtPJkzMeta || {},
    alias: indexGl2NHtPJkzMeta?.alias || [],
    redirect: indexGl2NHtPJkzMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/arvin-code-interpreter/index.vue").then(m => m.default || m)
  },
  {
    name: arvin_45instructionOydWRfdEnYMeta?.name ?? "arvin-instruction",
    path: arvin_45instructionOydWRfdEnYMeta?.path ?? "/arvin-instruction",
    meta: arvin_45instructionOydWRfdEnYMeta || {},
    alias: arvin_45instructionOydWRfdEnYMeta?.alias || [],
    redirect: arvin_45instructionOydWRfdEnYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/arvin-instruction.vue").then(m => m.default || m)
  },
  {
    name: auth_45landingRdNw2AGf1YMeta?.name ?? "auth-landing",
    path: auth_45landingRdNw2AGf1YMeta?.path ?? "/auth-landing",
    meta: auth_45landingRdNw2AGf1YMeta || {},
    alias: auth_45landingRdNw2AGf1YMeta?.alias || [],
    redirect: auth_45landingRdNw2AGf1YMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/auth-landing.vue").then(m => m.default || m)
  },
  {
    name: _91name_93mAbDUwc4KWMeta?.name ?? "best-gpts-arvin-gpts-name",
    path: _91name_93mAbDUwc4KWMeta?.path ?? "/best-gpts/arvin-gpts/:name()",
    meta: _91name_93mAbDUwc4KWMeta || {},
    alias: _91name_93mAbDUwc4KWMeta?.alias || [],
    redirect: _91name_93mAbDUwc4KWMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/best-gpts/arvin-gpts/[name].vue").then(m => m.default || m)
  },
  {
    name: indexDZWyRtmLvnMeta?.name ?? "best-gpts-arvin-gpts",
    path: indexDZWyRtmLvnMeta?.path ?? "/best-gpts/arvin-gpts",
    meta: indexDZWyRtmLvnMeta || {},
    alias: indexDZWyRtmLvnMeta?.alias || [],
    redirect: indexDZWyRtmLvnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/best-gpts/arvin-gpts/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_937gVGLiLeDeMeta?.name ?? "chatgpt-prompt-generator-path-id",
    path: _91id_937gVGLiLeDeMeta?.path ?? "/chatgpt-prompt-generator/:path()/:id()",
    meta: _91id_937gVGLiLeDeMeta || {},
    alias: _91id_937gVGLiLeDeMeta?.alias || [],
    redirect: _91id_937gVGLiLeDeMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/chatgpt-prompt-generator/[path]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexu6Zs4k2FsbMeta?.name ?? "chatgpt-prompt-generator-path",
    path: indexu6Zs4k2FsbMeta?.path ?? "/chatgpt-prompt-generator/:path()",
    meta: indexu6Zs4k2FsbMeta || {},
    alias: indexu6Zs4k2FsbMeta?.alias || [],
    redirect: indexu6Zs4k2FsbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/chatgpt-prompt-generator/[path]/index.vue").then(m => m.default || m)
  },
  {
    name: dataMgP74CPFAYMeta?.name ?? "chatgpt-prompt-generator-data",
    path: dataMgP74CPFAYMeta?.path ?? "/chatgpt-prompt-generator/data",
    meta: dataMgP74CPFAYMeta || {},
    alias: dataMgP74CPFAYMeta?.alias || [],
    redirect: dataMgP74CPFAYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/chatgpt-prompt-generator/data.ts").then(m => m.default || m)
  },
  {
    name: indexroQr2L2BPvMeta?.name ?? "chatgpt-prompt-generator",
    path: indexroQr2L2BPvMeta?.path ?? "/chatgpt-prompt-generator",
    meta: indexroQr2L2BPvMeta || {},
    alias: indexroQr2L2BPvMeta?.alias || [],
    redirect: indexroQr2L2BPvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/chatgpt-prompt-generator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdlsQ16DQ8vMeta?.name ?? "code-interpreter",
    path: indexdlsQ16DQ8vMeta?.path ?? "/code-interpreter",
    meta: indexdlsQ16DQ8vMeta || {},
    alias: indexdlsQ16DQ8vMeta?.alias || [],
    redirect: indexdlsQ16DQ8vMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/code-interpreter/index.vue").then(m => m.default || m)
  },
  {
    name: contact7W4kzReuOHMeta?.name ?? "contact",
    path: contact7W4kzReuOHMeta?.path ?? "/contact",
    meta: contact7W4kzReuOHMeta || {},
    alias: contact7W4kzReuOHMeta?.alias || [],
    redirect: contact7W4kzReuOHMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: cookiese17DNDecxYMeta?.name ?? "cookies",
    path: cookiese17DNDecxYMeta?.path ?? "/cookies",
    meta: cookiese17DNDecxYMeta || {},
    alias: cookiese17DNDecxYMeta?.alias || [],
    redirect: cookiese17DNDecxYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: ai_45article_45writer1rsLuLaUe0Meta?.name ?? "feature-ai-article-writer",
    path: ai_45article_45writer1rsLuLaUe0Meta?.path ?? "/feature/ai-article-writer",
    meta: ai_45article_45writer1rsLuLaUe0Meta || {},
    alias: ai_45article_45writer1rsLuLaUe0Meta?.alias || [],
    redirect: ai_45article_45writer1rsLuLaUe0Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-article-writer.vue").then(m => m.default || m)
  },
  {
    name: ai_45email_45response_45generator9193tNQTTlMeta?.name ?? "feature-ai-email-response-generator",
    path: ai_45email_45response_45generator9193tNQTTlMeta?.path ?? "/feature/ai-email-response-generator",
    meta: ai_45email_45response_45generator9193tNQTTlMeta || {},
    alias: ai_45email_45response_45generator9193tNQTTlMeta?.alias || [],
    redirect: ai_45email_45response_45generator9193tNQTTlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-email-response-generator.vue").then(m => m.default || m)
  },
  {
    name: ai_45grammar_45checkerNRRxrsGWd5Meta?.name ?? "feature-ai-grammar-checker",
    path: ai_45grammar_45checkerNRRxrsGWd5Meta?.path ?? "/feature/ai-grammar-checker",
    meta: ai_45grammar_45checkerNRRxrsGWd5Meta || {},
    alias: ai_45grammar_45checkerNRRxrsGWd5Meta?.alias || [],
    redirect: ai_45grammar_45checkerNRRxrsGWd5Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-grammar-checker.vue").then(m => m.default || m)
  },
  {
    name: ai_45logo_45designerfli4FS4lZWMeta?.name ?? "feature-ai-logo-designer",
    path: ai_45logo_45designerfli4FS4lZWMeta?.path ?? "/feature/ai-logo-designer",
    meta: ai_45logo_45designerfli4FS4lZWMeta || {},
    alias: ai_45logo_45designerfli4FS4lZWMeta?.alias || [],
    redirect: ai_45logo_45designerfli4FS4lZWMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-logo-designer.vue").then(m => m.default || m)
  },
  {
    name: ai_45painterdIY2D8QPo2Meta?.name ?? "feature-ai-painter",
    path: ai_45painterdIY2D8QPo2Meta?.path ?? "/feature/ai-painter",
    meta: ai_45painterdIY2D8QPo2Meta || {},
    alias: ai_45painterdIY2D8QPo2Meta?.alias || [],
    redirect: ai_45painterdIY2D8QPo2Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-painter.vue").then(m => m.default || m)
  },
  {
    name: ai_45signature_45generatordsQeDmmbDRMeta?.name ?? "feature-ai-signature-generator",
    path: ai_45signature_45generatordsQeDmmbDRMeta?.path ?? "/feature/ai-signature-generator",
    meta: ai_45signature_45generatordsQeDmmbDRMeta || {},
    alias: ai_45signature_45generatordsQeDmmbDRMeta?.alias || [],
    redirect: ai_45signature_45generatordsQeDmmbDRMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/ai-signature-generator.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45access_45gpt_454oiJ76rkRNKMeta?.name ?? "feature-how-to-access-gpt-4",
    path: how_45to_45access_45gpt_454oiJ76rkRNKMeta?.path ?? "/feature/how-to-access-gpt-4",
    meta: how_45to_45access_45gpt_454oiJ76rkRNKMeta || {},
    alias: how_45to_45access_45gpt_454oiJ76rkRNKMeta?.alias || [],
    redirect: how_45to_45access_45gpt_454oiJ76rkRNKMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/how-to-access-gpt-4.vue").then(m => m.default || m)
  },
  {
    name: web_45translatorb474fGF6zJMeta?.name ?? "feature-web-translator",
    path: web_45translatorb474fGF6zJMeta?.path ?? "/feature/web-translator",
    meta: web_45translatorb474fGF6zJMeta || {},
    alias: web_45translatorb474fGF6zJMeta?.alias || [],
    redirect: web_45translatorb474fGF6zJMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/web-translator.vue").then(m => m.default || m)
  },
  {
    name: writing_45improverSNc8mmAZsrMeta?.name ?? "feature-writing-improver",
    path: writing_45improverSNc8mmAZsrMeta?.path ?? "/feature/writing-improver",
    meta: writing_45improverSNc8mmAZsrMeta || {},
    alias: writing_45improverSNc8mmAZsrMeta?.alias || [],
    redirect: writing_45improverSNc8mmAZsrMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feature/writing-improver.vue").then(m => m.default || m)
  },
  {
    name: feedbacklifjFfzv0GMeta?.name ?? "feedback",
    path: feedbacklifjFfzv0GMeta?.path ?? "/feedback",
    meta: feedbacklifjFfzv0GMeta || {},
    alias: feedbacklifjFfzv0GMeta?.alias || [],
    redirect: feedbacklifjFfzv0GMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: credit_45deduction_45ruleXsCjFL5jkuMeta?.name ?? "help-credit-deduction-rule",
    path: credit_45deduction_45ruleXsCjFL5jkuMeta?.path ?? "/help/credit-deduction-rule",
    meta: credit_45deduction_45ruleXsCjFL5jkuMeta || {},
    alias: credit_45deduction_45ruleXsCjFL5jkuMeta?.alias || [],
    redirect: credit_45deduction_45ruleXsCjFL5jkuMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/help/credit-deduction-rule.vue").then(m => m.default || m)
  },
  {
    name: indexhnNeJ0kkEdMeta?.name ?? "image-tools-path",
    path: indexhnNeJ0kkEdMeta?.path ?? "/image-tools/:path()",
    meta: indexhnNeJ0kkEdMeta || {},
    alias: indexhnNeJ0kkEdMeta?.alias || [],
    redirect: indexhnNeJ0kkEdMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/image-tools/[path]/index.vue").then(m => m.default || m)
  },
  {
    name: image7wytoUvZH4Meta?.name ?? "image-tools-image",
    path: image7wytoUvZH4Meta?.path ?? "/image-tools/image",
    meta: image7wytoUvZH4Meta || {},
    alias: image7wytoUvZH4Meta?.alias || [],
    redirect: image7wytoUvZH4Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/image-tools/image.vue").then(m => m.default || m)
  },
  {
    name: indexa4oJHZcZybMeta?.name ?? "image-tools",
    path: indexa4oJHZcZybMeta?.path ?? "/image-tools",
    meta: indexa4oJHZcZybMeta || {},
    alias: indexa4oJHZcZybMeta?.alias || [],
    redirect: indexa4oJHZcZybMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/image-tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexM0pZeEN8pUMeta?.name ?? "index",
    path: indexM0pZeEN8pUMeta?.path ?? "/",
    meta: indexM0pZeEN8pUMeta || {},
    alias: indexM0pZeEN8pUMeta?.alias || [],
    redirect: indexM0pZeEN8pUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: emptyQRE4YytTdHMeta?.name ?? "invitation-empty",
    path: emptyQRE4YytTdHMeta?.path ?? "/invitation/empty",
    meta: emptyQRE4YytTdHMeta || {},
    alias: emptyQRE4YytTdHMeta?.alias || [],
    redirect: emptyQRE4YytTdHMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/invitation/empty.vue").then(m => m.default || m)
  },
  {
    name: indexfdp0H28hMFMeta?.name ?? "invitation",
    path: indexfdp0H28hMFMeta?.path ?? "/invitation",
    meta: indexfdp0H28hMFMeta || {},
    alias: indexfdp0H28hMFMeta?.alias || [],
    redirect: indexfdp0H28hMFMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/invitation/index.vue").then(m => m.default || m)
  },
  {
    name: InviteCopyUrlluzkGieyyDMeta?.name ?? "invitation-InviteCopyUrl",
    path: InviteCopyUrlluzkGieyyDMeta?.path ?? "/invitation/InviteCopyUrl",
    meta: InviteCopyUrlluzkGieyyDMeta || {},
    alias: InviteCopyUrlluzkGieyyDMeta?.alias || [],
    redirect: InviteCopyUrlluzkGieyyDMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/invitation/InviteCopyUrl.vue").then(m => m.default || m)
  },
  {
    name: invite79S0q4iu2JMeta?.name ?? "invite",
    path: invite79S0q4iu2JMeta?.path ?? "/invite",
    meta: invite79S0q4iu2JMeta || {},
    alias: invite79S0q4iu2JMeta?.alias || [],
    redirect: invite79S0q4iu2JMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/invite.vue").then(m => m.default || m)
  },
  {
    name: dataWxhnD6953VMeta?.name ?? "PDF-tools-data",
    path: dataWxhnD6953VMeta?.path ?? "/PDF-tools/data",
    meta: dataWxhnD6953VMeta || {},
    alias: dataWxhnD6953VMeta?.alias || [],
    redirect: dataWxhnD6953VMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/PDF-tools/data.ts").then(m => m.default || m)
  },
  {
    name: pdf_45to_45_91type_93XjCflYj8ZDMeta?.name ?? "PDF-tools-pdf-to-type",
    path: pdf_45to_45_91type_93XjCflYj8ZDMeta?.path ?? "/PDF-tools/pdf-to-:type()",
    meta: pdf_45to_45_91type_93XjCflYj8ZDMeta || {},
    alias: pdf_45to_45_91type_93XjCflYj8ZDMeta?.alias || [],
    redirect: pdf_45to_45_91type_93XjCflYj8ZDMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/PDF-tools/pdf-to-[type].vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyuVoE9hW38FMeta?.name ?? "privacy-policy",
    path: privacy_45policyuVoE9hW38FMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyuVoE9hW38FMeta || {},
    alias: privacy_45policyuVoE9hW38FMeta?.alias || [],
    redirect: privacy_45policyuVoE9hW38FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexsk3k9xhh4wMeta?.name ?? "search",
    path: indexsk3k9xhh4wMeta?.path ?? "/search",
    meta: indexsk3k9xhh4wMeta || {},
    alias: indexsk3k9xhh4wMeta?.alias || [],
    redirect: indexsk3k9xhh4wMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: resultLhP1nPJe3UMeta?.name ?? "search-result",
    path: resultLhP1nPJe3UMeta?.path ?? "/search/result",
    meta: resultLhP1nPJe3UMeta || {},
    alias: resultLhP1nPJe3UMeta?.alias || [],
    redirect: resultLhP1nPJe3UMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/search/result.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93sjJPI0zAWwMeta?.name ?? "search-result-id",
    path: _91id_93sjJPI0zAWwMeta?.path ?? ":id()",
    meta: _91id_93sjJPI0zAWwMeta || {},
    alias: _91id_93sjJPI0zAWwMeta?.alias || [],
    redirect: _91id_93sjJPI0zAWwMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/search/result/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93JiLYkZER9SMeta?.name ?? "share-chat-id",
    path: _91id_93JiLYkZER9SMeta?.path ?? "/share/chat/:id()",
    meta: _91id_93JiLYkZER9SMeta || {},
    alias: _91id_93JiLYkZER9SMeta?.alias || [],
    redirect: _91id_93JiLYkZER9SMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/share/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceQlgjd43P1ZMeta?.name ?? "terms-of-service",
    path: terms_45of_45serviceQlgjd43P1ZMeta?.path ?? "/terms-of-service",
    meta: terms_45of_45serviceQlgjd43P1ZMeta || {},
    alias: terms_45of_45serviceQlgjd43P1ZMeta?.alias || [],
    redirect: terms_45of_45serviceQlgjd43P1ZMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JUTVpPttFdMeta?.name ?? "user-id",
    path: _91id_93JUTVpPttFdMeta?.path ?? "/user/:id()",
    meta: _91id_93JUTVpPttFdMeta || {},
    alias: _91id_93JUTVpPttFdMeta?.alias || [],
    redirect: _91id_93JUTVpPttFdMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/[id].vue").then(m => m.default || m)
  },
  {
    name: guidet0RuBHWKfuMeta?.name ?? "user-guide",
    path: guidet0RuBHWKfuMeta?.path ?? "/user/guide",
    meta: guidet0RuBHWKfuMeta || {},
    alias: guidet0RuBHWKfuMeta?.alias || [],
    redirect: guidet0RuBHWKfuMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/guide.vue").then(m => m.default || m)
  },
  {
    name: instructionDjYi7glQ70Meta?.name ?? "user-instruction",
    path: instructionDjYi7glQ70Meta?.path ?? "/user/instruction",
    meta: instructionDjYi7glQ70Meta || {},
    alias: instructionDjYi7glQ70Meta?.alias || [],
    redirect: instructionDjYi7glQ70Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/instruction.vue").then(m => m.default || m)
  },
  {
    name: loginNGeL9ogsoVMeta?.name ?? "user-login",
    path: loginNGeL9ogsoVMeta?.path ?? "/user/login",
    meta: loginNGeL9ogsoVMeta || {},
    alias: loginNGeL9ogsoVMeta?.alias || [],
    redirect: loginNGeL9ogsoVMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45password2hY2w7avtkMeta?.name ?? "user-reset-password",
    path: reset_45password2hY2w7avtkMeta?.path ?? "/user/reset-password",
    meta: reset_45password2hY2w7avtkMeta || {},
    alias: reset_45password2hY2w7avtkMeta?.alias || [],
    redirect: reset_45password2hY2w7avtkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/reset-password.vue").then(m => m.default || m)
  },
  {
    name: subscriptioncm3QrxeMuzMeta?.name ?? "user-subscription",
    path: subscriptioncm3QrxeMuzMeta?.path ?? "/user/subscription",
    meta: subscriptioncm3QrxeMuzMeta || {},
    alias: subscriptioncm3QrxeMuzMeta?.alias || [],
    redirect: subscriptioncm3QrxeMuzMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/subscription.vue").then(m => m.default || m)
  },
  {
    name: welcomegwO4INugt2Meta?.name ?? "user-welcome",
    path: welcomegwO4INugt2Meta?.path ?? "/user/welcome",
    meta: welcomegwO4INugt2Meta || {},
    alias: welcomegwO4INugt2Meta?.alias || [],
    redirect: welcomegwO4INugt2Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/user/welcome.vue").then(m => m.default || m)
  },
  {
    name: webstoreLMloLGwxM4Meta?.name ?? "webstore",
    path: webstoreLMloLGwxM4Meta?.path ?? "/webstore",
    meta: webstoreLMloLGwxM4Meta || {},
    alias: webstoreLMloLGwxM4Meta?.alias || [],
    redirect: webstoreLMloLGwxM4Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/pages/webstore.vue").then(m => m.default || m)
  }
]